var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"faq-search",style:({
      backgroundImage:("url(" + (require('@/assets/images/banner/store-connect-banner.svg')) + ")"),
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }),attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"text-center"},[_c('h2',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.$t('This account has not been bound to any shop'))+" ⚠️ ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('Please contact beesummer, representative will assist you asap'))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}},{name:"b-modal",rawName:"v-b-modal.store-application",modifiers:{"store-application":true}}],attrs:{"variant":"primary","disabled":_vm.user.applications && _vm.user.applications.length > 0}},[(_vm.user.applications && _vm.user.applications.length > 0)?_c('span',[_vm._v(_vm._s(_vm.$t('reviewing')))]):_c('span',[_vm._v(_vm._s(_vm.$t('apply for new store')))])])],1)],1),_c('validation-observer',{ref:"add-loan-modal",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"id":"store-application","title":_vm.$t('loan'),"hide-header-close":true,"no-close-on-backdrop":true,"no-close-on-esc":true,"ok-title":_vm.$t('save'),"cancel-title":_vm.$t('cancel'),"cancel-variant":"outline-primary","footer-class":"justify-content-end flex-row-reverse","centered":"","ok-disabled":invalid},on:{"ok":_vm.handleSubmit}},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('store name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('store name'),"label-for":"store-name"}},[_c('b-form-input',{attrs:{"id":"store-name","placeholder":_vm.$t('store name')},model:{value:(_vm.storeApplication.storeName),callback:function ($$v) {_vm.$set(_vm.storeApplication, "storeName", $$v)},expression:"storeApplication.storeName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('store type'),"label-for":"store-type"}},[_c('b-dropdown',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1 full-width-dropdown",attrs:{"id":"store-type","variant":"outline-primary","block":"","text":_vm.$t(_vm.storeApplication.storeType)}},_vm._l((_vm.StoreTypes),function(item){return _c('b-dropdown-item',{key:item.key,on:{"click":function($event){return _vm.handleStoreType(item.key)}}},[_vm._v(" "+_vm._s(_vm.$t(item.key))+" ")])}),1)],1)],1)],1),(_vm.storeApplication.storeType === 'registered_company' || _vm.storeApplication.storeType === 'pawnshop')?_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('tax id'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('tax id'),"label-for":"tax-id"}},[_c('b-form-input',{attrs:{"id":"tax-id","placeholder":_vm.$t('tax id')},model:{value:(_vm.storeApplication.taxId),callback:function ($$v) {_vm.$set(_vm.storeApplication, "taxId", $$v)},expression:"storeApplication.taxId"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('contact name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('contact name'),"label-for":"contact-name"}},[_c('b-form-input',{attrs:{"id":"contact-number","placeholder":_vm.$t('contact name')},model:{value:(_vm.storeApplication.contactNumber),callback:function ($$v) {_vm.$set(_vm.storeApplication, "contactNumber", $$v)},expression:"storeApplication.contactNumber"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":_vm.$t('contact number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('contact number'),"label-for":"contact-number"}},[_c('b-form-input',{attrs:{"id":"contact-number","placeholder":_vm.$t('contact number')},model:{value:(_vm.storeApplication.contactNumber),callback:function ($$v) {_vm.$set(_vm.storeApplication, "contactNumber", $$v)},expression:"storeApplication.contactNumber"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }