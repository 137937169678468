<template>
  <div>
    <b-card
      no-body
      class="faq-search"
      :style="{
        backgroundImage:`url(${require('@/assets/images/banner/store-connect-banner.svg')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }"
    >
      <b-card-body class="text-center">
        <h2 class="text-primary">
          {{ $t('This account has not been bound to any shop') }} ⚠️
        </h2>
        <b-card-text class="mb-2">
          {{ $t('Please contact beesummer, representative will assist you asap') }}
        </b-card-text>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-modal.store-application
          variant="primary"
          :disabled="user.applications && user.applications.length > 0"
        >
          <span v-if="user.applications && user.applications.length > 0">{{ $t('reviewing') }}</span><span v-else>{{ $t('apply for new store') }}</span>
        </b-button>
      </b-card-body>
    </b-card>
    <validation-observer
      ref="add-loan-modal"
      v-slot="{ invalid }"
    >
      <b-modal
        id="store-application"
        :title="$t('loan')"
        :hide-header-close="true"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        :ok-title="$t('save')"
        :cancel-title="$t('cancel')"
        cancel-variant="outline-primary"
        footer-class="justify-content-end flex-row-reverse"
        centered
        :ok-disabled="invalid"
        @ok="handleSubmit"
      >
        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              :name="$t('store name')"
              rules="required"
            >
              <b-form-group
                :label="$t('store name')"
                label-for="store-name"
              >
                <b-form-input
                  id="store-name"
                  v-model="storeApplication.storeName"
                  :placeholder="$t('store name')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              :label="$t('store type')"
              label-for="store-type"
            >
              <b-dropdown
                id="store-type"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mb-1 full-width-dropdown"
                variant="outline-primary"
                block
                :text="$t(storeApplication.storeType)"
              >
                <b-dropdown-item
                  v-for="item in StoreTypes"
                  :key="item.key"
                  @click="handleStoreType(item.key)"
                >
                  {{ $t(item.key) }}
                </b-dropdown-item>
              </b-dropdown>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="storeApplication.storeType === 'registered_company' || storeApplication.storeType === 'pawnshop'">
          <b-col>
            <validation-provider
              #default="{ errors }"
              :name="$t('tax id')"
              rules="required"
            >
              <b-form-group
                :label="$t('tax id')"
                label-for="tax-id"
              >
                <b-form-input
                  id="tax-id"
                  v-model="storeApplication.taxId"
                  :placeholder="$t('tax id')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              :name="$t('contact name')"
              rules="required"
            >
              <b-form-group
                :label="$t('contact name')"
                label-for="contact-name"
              >
                <b-form-input
                  id="contact-number"
                  v-model="storeApplication.contactNumber"
                  :placeholder="$t('contact name')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              :name="$t('contact number')"
              rules="required"
            >
              <b-form-group
                :label="$t('contact number')"
                label-for="contact-number"
              >
                <b-form-input
                  id="contact-number"
                  v-model="storeApplication.contactNumber"
                  :placeholder="$t('contact number')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </b-modal>
    </validation-observer>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BCardText, BButton, BModal, BRow, BCol, BDropdown, BDropdownItem, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate'
import { required } from '@validations'
import * as rules from 'vee-validate/dist/rules'
import store from '@/store'

import { StoreTypes } from '@/store/cfg'
import { mapGetters } from 'vuex'

localize('tw')
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

export default {
  components: {
    BCard,
    BModal,
    BCardBody,
    BCardText,
    BButton,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      StoreTypes,
      storeApplication: {
        storeType: 'registered_company',
        storeName: '',
        taxId: null,
        contactNumber: '',
        contactName: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  methods: {
    handleStoreType(key) {
      this.storeApplication.storeType = key
    },
    handleSubmit() {
      store.dispatch('addToApplications', this.storeApplication)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
